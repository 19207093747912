import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { routes } from './routes';

const AppRoutes = () => {
  const makeRoute = (route: any, key: number) => {
    return <Route key={key} path={route.path} element={<route.element />} />;
  };

  return (
    <Routes>
      {routes.map((route: any, index: number) => makeRoute(route, index))}
    </Routes>
  );
};

export default AppRoutes;
