// import React from 'react';
import loadable from '@loadable/component';

const Home = loadable(() => import('pages/home'));
const Portal = loadable(() => import('pages/portal'));
const Claim = loadable(() => import('pages/claim'));
// const Mint = loadable(() => import('pages/mint'));

// const Error404Page = () => {
//   return <div>Sorry this page does not exist</div>;
// };

export const routes = [
  {
    path: '/',
    element: Home,
  },
  {
    path: '/claim',
    element: Claim,
  },
  // {
  //   path: '/mint',
  //   element: Mint,
  // },
  {
    path: '/portal',
    element: Portal,
  },
  {
    path: '*',
    element: Home,
  },
];
