export enum WalletStatus {
  CONNECTED,
  DISCONNECTED,
  NOT_SUPPORTED,
  NOT_SET,
}

export enum Gradients {
  ORANGE = 'orange',
  ZIMMY_PURPLE = 'zimmy-purple',
  ROSE = 'rose',
  ASH = 'ash',
  BLUE = 'blue',
  EARTHLY = 'earthly',
  MARGO = 'margo',
  PURPLE = 'purple',
  MEADOW = 'meadow',
  MIST = 'mist',
  SHORE = 'shore',
  EGG = 'egg',
}

export enum PortalMode {
  MINT,
  OPENSEA,
  CLAIM,
}
