import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { WalletStatus } from 'utils/enums';

export interface WalletState {
  address: string;
  status: WalletStatus;
}

const initialState: WalletState = {
  address: '',
  status: WalletStatus.NOT_SET,
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setStatus: (state, action: PayloadAction<WalletStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { setAddress, setStatus } = walletSlice.actions;

export const selectWalletAddress = (state: RootState) => state.wallet.address;
export const selectWalletStatus = (state: RootState) => state.wallet.status;

export default walletSlice.reducer;
